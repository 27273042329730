import { passwordValidation } from '@motabilityoperations/validation';

export function passwordContainsInvalidCharacters(password: string): boolean {
  return passwordValidation.passwordContainsInvalidCharacters(password);
}

export function passwordDoesNotMeetCriteria(password: string): boolean {
  return (
    passwordValidation.passwordIsBlank(password) ||
    passwordValidation.passwordTooShort(password) ||
    passwordValidation.passwordTooLong(password) ||
    passwordValidation.passwordMissingRequiredCharacters(password)
  );
}
