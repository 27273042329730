import { useLocation } from 'react-router-dom';
import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect } from 'react';
import { signupSourceValidation } from '@motabilityoperations/validation';

export interface SignupSource {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmId?: string;
}

interface SetSignupSourceProps {
  setSignupSource: Dispatch<SetStateAction<SignupSource | undefined>>;
  children: ReactNode;
}

export function SetSignupSource({ setSignupSource, children }: SetSignupSourceProps): ReactElement {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utmSource = extractValueFor('utm_source', queryParams);
    const utmMedium = extractValueFor('utm_medium', queryParams);
    const utmCampaign = extractValueFor('utm_campaign', queryParams);
    const utmId = extractValueFor('utm_id', queryParams);

    setSignupSource({ utmSource, utmMedium, utmCampaign, utmId });
  }, [location, setSignupSource]);

  return <>{children}</>;
}

function extractValueFor(param: string, queryParams: URLSearchParams): string {
  const queryParamValue = queryParams.get(param) ?? '';
  return signupSourceValidation.signupSourceFailsSimpleFormatChecks(queryParamValue) ? '' : queryParamValue;
}
