import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { NEW_TO_SCHEME_EMAIL } from '../routes';
import { Page } from '../../../components/page/Page';

interface EmailSentProps {
  email: string;
}

export function EmailSentPage({ email }: EmailSentProps): ReactElement {
  return (
    <Page title={'Please verify email'} withSecurityPromise={false}>
      <p>
        We have sent an email to <strong data-cs-mask={'data-cs-mask'}>{email}</strong> in order to verify your
        identity. Your account setup won’t be complete until you have followed the confirmation button in the email.
        Please note, for security reasons you will need to do this within 2 hours.
      </p>

      <p>
        <strong>Didn’t receive anything?</strong>
        <br />
        Please check your spam folder. If you may have entered the wrong email address, you can{' '}
        <Link to={NEW_TO_SCHEME_EMAIL}>provide your email address again</Link>.
      </p>
    </Page>
  );
}
